body {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

/* appear - on page load */
.fade-appear {
    opacity: 0;
    z-index: 1;
}
.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 1000ms linear;
}

/* enter */
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 5000ms linear 5000ms;
}

/* exit */
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 5000ms linear;
}
.fade-exit-done {
    opacity: 0;
}

/* slide enter */
.slide-enter {
    opacity: 0;
    transform: scale(0.97) translateY(5px);
    z-index: 1;
}
.slide-enter.slide-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: opacity 3000ms linear 1000ms, transform 3000ms ease-in-out 1000ms;
}

/* slide exit */
.slide-exit {
    opacity: 1;
    transform: scale(1) translateY(0);
}
.slide-exit.slide-exit-active {
    opacity: 0;
    transform: scale(0.97) translateY(5px);
    transition: opacity 1500ms linear, transform 1500ms ease-out;
}
.slide-exit-done {
    opacity: 0;
}

.BrainhubCarouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
